import classNames from "classnames";
import React from "react";

interface LineTitleProps {
  text: string;
  className?: string;
}

const LineTitle: React.FC<LineTitleProps> = (props) => {
  return (
    <h2
      className={classNames(
        "w-full text-center text-[#6d6d6d] border-b border-b-[#9e9e9e] my-4 leading-[0.1em]",
        props?.className
      )}
    >
      <span className="bg-white px-4">{props.text}</span>
    </h2>
  );
};

export default LineTitle;
